import OrganizationService from '@/services/organization.service'

export const GET_VIEW_ORGANIZATION_REQUEST = 'GET_VIEW_ORGANIZATION_REQUEST'
export const GET_VIEW_ORGANIZATION_SUCCESS = 'GET_VIEW_ORGANIZATION_SUCCESS'
export const GET_VIEW_ORGANIZATION_FAILURE = 'GET_VIEW_ORGANIZATION_FAILURE'
export const GET_MY_ORGANIZATIONS_SUCCESS = 'GET_MY_ORGANIZATIONS_SUCCESS'
export const GET_MY_ORGANIZATIONS_FAILURE = 'GET_MY_ORGANIZATIONS_FAILURE'
export const GET_MY_ORGANIZATION_REQUEST = 'GET_MY_ORGANIZATION_REQUEST'
export const GET_MY_ORGANIZATION_SUCCESS = 'GET_MY_ORGANIZATION_SUCCESS'
export const GET_MY_ORGANIZATION_FAILURE = 'GET_MY_ORGANIZATION_FAILURE'
export const GET_VIEW_PROJECT_REQUEST = 'GET_VIEW_PROJECT_REQUEST'
export const GET_VIEW_PROJECT_SUCCESS = 'GET_VIEW_PROJECT_SUCCESS'
export const GET_VIEW_PROJECT_FAILURE = 'GET_VIEW_PROJECT_FAILURE'
export const GET_MY_PROJECT_REQUEST = 'GET_MY_PROJECT_REQUEST'
export const GET_MY_PROJECT_SUCCESS = 'GET_MY_PROJECT_SUCCESS'
export const GET_MY_PROJECT_FAILURE = 'GET_MY_PROJECT_FAILURE'

export const getViewOrganizationRequest = () => ({ type: GET_VIEW_ORGANIZATION_REQUEST })
export const getViewOrganizationSuccess = (data) => ({ type: GET_VIEW_ORGANIZATION_SUCCESS, payload: data })
export const getViewOrganizationFailure = (error) => ({ type: GET_VIEW_ORGANIZATION_FAILURE, payload: error })
export const getMyOrganizationsSuccess = (data) => ({ type: GET_MY_ORGANIZATIONS_SUCCESS, payload: data })
export const getMyOrganizationsFailure = (error) => ({ type: GET_MY_ORGANIZATIONS_FAILURE, payload: error })
export const getMyOrganizationRequest = () => ({ type: GET_MY_ORGANIZATION_REQUEST })
export const getMyOrganizationSuccess = (data) => ({ type: GET_MY_ORGANIZATION_SUCCESS, payload: data })
export const getMyOrganizationFailure = (error) => ({ type: GET_MY_ORGANIZATION_FAILURE, payload: error })
export const getViewProjectRequest = () => ({ type: GET_VIEW_PROJECT_REQUEST })
export const getViewProjectSuccess = (data) => ({ type: GET_VIEW_PROJECT_SUCCESS, payload: data })
export const getViewProjectFailure = (error) => ({ type: GET_VIEW_PROJECT_FAILURE, payload: error })
export const getMyProjectRequest = () => ({ type: GET_MY_PROJECT_REQUEST })
export const getMyProjectSuccess = (data) => ({ type: GET_MY_PROJECT_SUCCESS, payload: data })
export const getMyProjectFailure = (error) => ({ type: GET_MY_PROJECT_FAILURE, payload: error })

export const getOrganizationById = (id) => async (dispatch) => {
  try {
    dispatch(getViewOrganizationRequest())
    const token = localStorage.getItem('token')
    const result = await OrganizationService.getOrganizationById(id, token)
    console.log(result)
    if (result.success) {
      dispatch(getViewOrganizationSuccess(result.organization))
    } else {
      dispatch(getViewOrganizationFailure(result.message))
    }
  } catch (e) {
    dispatch(getViewOrganizationFailure(e.toString()))
  }
}

export const getMyOrganizations = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OrganizationService.getMyOrganizations(id, token)
    console.log(result)
    if (result.success) {
      dispatch(getMyOrganizationsSuccess(result.organizations))
    } else {
      dispatch(getMyOrganizationsFailure(result.message))
    }
  } catch (e) {
    dispatch(getMyOrganizationsFailure(e.toString()))
  }
}

export const getMyOrganizationById = (id) => async (dispatch) => {
  try {
    dispatch(getMyOrganizationRequest())
    const token = localStorage.getItem('token')
    const result = await OrganizationService.getMyOrganizationById(id, token)
    console.log(result)
    if (result.success) {
      dispatch(getMyOrganizationSuccess(result.organization))
    } else {
      dispatch(getMyOrganizationFailure(result.message))
    }
  } catch (e) {
    dispatch(getMyOrganizationFailure(e.toString()))
  }
}

export const getProjectById = (id) => async (dispatch) => {
  try {
    dispatch(getViewProjectRequest())
    const token = localStorage.getItem('token')
    const result = await OrganizationService.getProjectById(id, token)
    console.log(result)
    if (result.success) {
      dispatch(getViewProjectSuccess(result.project))
    } else {
      dispatch(getViewProjectFailure(result.message))
    }
  } catch (e) {
    dispatch(getViewProjectFailure(e.toString()))
  }
}

export const getMyProjectById = (id) => async (dispatch) => {
  try {
    dispatch(getMyProjectRequest())
    const token = localStorage.getItem('token')
    const result = await OrganizationService.getMyProjectById(id, token)
    console.log(result)
    if (result.success) {
      dispatch(getMyProjectSuccess(result.project))
    } else {
      dispatch(getMyProjectFailure(result.message))
    }
  } catch (e) {
    dispatch(getMyProjectFailure(e.toString()))
  }
}
