import api from './api'

class OrganizationService
{
	async getOrganizationById(id, token) {
		try {
			const response = await api.get(`/organization/one/${id}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][getOrganizationById][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getMyOrganizations(id, token) {
		try {
			const response = await api.get(`/organization/my`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][getMyOrganizations][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getMyOrganizationById(id, token) {
		try {
			const response = await api.get(`/organization/my/${id}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][getMyOrganizationById][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getProjectById(id, token) {
		try {
			const response = await api.get(`/organization/project/${id}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][getProjectById][error]', e)
			return { success: false, message: e.toString() }
		}
	}
	
	async getMyProjectById(id, token) {
		try {
			const response = await api.get(`/organization/project/my/${id}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[OrganizationService][getMyProjectById][error]', e)
			return { success: false, message: e.toString() }
		}
	}
}

export default new OrganizationService()
