import { useState, useEffect } from 'react'
import { emptyFilters, offerTypes, minSalary, maxSalary, salaryStep } from '@/constants/offer'
import { ucfirst } from '@/utils/content.utils'
import Select from 'react-select'
import MultiRangeSlider from 'multi-range-slider-react'
import arrow from '@/assets/images/search-arrow-down.svg'

import '@/components/Search/styles.css'

const SearchFields = ({ cities, activityTypes, onSearch }) => {
	const [query, setQuery] = useState('')
	const [filters, setFilters] = useState(emptyFilters)
	const [salaryVisible, setSalaryVisible] = useState(false)

	const handleInputChange = (e) => {
		const { value } = e.target
		onSearch({ query: value, filters })
		setQuery(value)
	}

	const handleSelectChange = (option, name) => {
		const { value } = option
		setFilters(prevFilters => ({ ...prevFilters, [name]: value }))
	}

	const toggleSalaryDropdown = () => {
		setSalaryVisible(!salaryVisible)
	}

	const [salaryFromValue, setSalaryFromValue] = useState(minSalary)
	const [salaryToValue, setSalaryToValue] = useState(maxSalary)

	const handleSalaryInputChange = (e) => {
		const { name, value } = e.target

		if (name === 'salary_from') {
			setSalaryFromValue(Number(value))
			if (value >= minSalary && value <= filters.salary_to) {
				setFilters(prevFilters => ({
					...prevFilters,
					salary_from: Number(value)
				}))				
			}
		}

		if (name === 'salary_to') {
			setSalaryToValue(Number(value))
			if (value >= filters.salary_from && value <= maxSalary) {
				setFilters(prevFilters => ({
					...prevFilters,
					salary_to: Number(value)
				}))				
			}
		}
	}

	const handleSalaryRangeChange = (e) => {
		setFilters(prevFilters => ({
			...prevFilters,
			salary_from: e.minValue,
			salary_to: e.maxValue
		}))
	}

	useEffect(() => {
	    setSalaryFromValue(filters.salary_from)
	    setSalaryToValue(filters.salary_to)
	}, [filters.salary_from, filters.salary_to])

	const handleSearch = () => {
		onSearch({ query, filters })
	}

	const clearFilters = () => {
		onSearch({ query, filters: emptyFilters })
		setFilters(emptyFilters)
	}

	return (
		<>
			<div className="block-search-fields">
				<div className="block-search-field">
					<input
						type="search"
						placeholder="wpisz poszukiwany wyraz"
						name="search"
						value={query}
						onChange={handleInputChange}
					/>
				</div>
				<div className="block-search-field block-search-field-25">
					<div className="select-field">
						<Select
							className="search-select"
							name="offer_type"
							placeholder="TYP: staż/praktyka/praca"
							value={Object.entries(offerTypes).map(([value, label]) => ({ value, label })).find(opt => opt.value === filters.offer_type) || null}
							options={Object.entries(offerTypes).map(([value, label]) => ({ value, label }))}
							onChange={(option) => handleSelectChange(option, 'offer_type')}
						/>
					</div>
				</div>
				<div className="block-search-field block-search-field-25">
					<div className="select-field">
						<Select
							className="search-select"
							name="city"
							placeholder="Miasto"
							value={Object.values(cities).map(item => ({ value: item, label: item })).find(opt => opt.value === filters.city) || null}
							options={Object.values(cities).map(item => ({ value: item, label: item }))}
							onChange={(option) => handleSelectChange(option, 'city')}
						/>
					</div>
				</div>
				<div className="block-search-field block-search-field-25">
					<div className="select-field">
						<Select
							className="search-select"
							name="activity_type"
							placeholder="Obszar działania"
							value={Object.values(activityTypes).map(item => ({ value: item, label: item })).find(opt => opt.value === filters.activity_type) || null}
							options={Object.values(activityTypes).map(item => ({ value: item, label: item }))}
							onChange={(option) => handleSelectChange(option, 'activity_type')}
						/>
					</div>
				</div>
				{/*<div className="block-search-field block-search-field-25">
					<div className="select-field">
						<span className="form-field-arrow">
							<select name="offer_type" value={filters.offer_type} onChange={handleSelectChange}>
								<option value="" disabled>TYP: staż/praktyka/praca</option>
								{Object.entries(offerTypes).map(([key, name]) => (
									<option value={name} key={key}>{ucfirst(name)}</option>
								))}
							</select>
							<span className="button"><img src={arrow} alt="" /></span>
						</span>
					</div>
				</div>*/}
				{/*<div className="block-search-field block-search-field-25">
					<div className="select-field">
						<span className="form-field-arrow">
							<select name="city" value={filters.city} onChange={handleSelectChange}>
								<option value="" disabled>Miasto</option>
								{Object.values(cities).map((item, index) => (
									<option value={item} key={index}>{item}</option>
								))}
							</select>
							<span className="button"><img src={arrow} alt="" /></span>
						</span>
					</div>
				</div>*/}
				{/*<div className="block-search-field block-search-field-25">
					<div className="select-field">
						<span className="form-field-arrow">
							<select name="activity_type" value={filters.activity_type} onChange={handleSelectChange}>
								<option value="" disabled>Obszar działania</option>
								{Object.values(activityTypes).map((item, index) => (
									<option value={item} key={index}>{item}</option>
								))}
							</select>
							<span className="button"><img src={arrow} alt="" /></span>
						</span>
					</div>
				</div>*/}
				<div className="block-search-field block-search-field-25">
					<div className="select-field">
						<span className="form-field-arrow">
							<div className={`block-search-field-salary ${salaryVisible ? 'dropdown' : ''}`}>
								<span onClick={toggleSalaryDropdown}>widelki płacowe</span>
								{salaryVisible && <div>
									<div className="block-search-field-salary-dropdown-fields">
										od <input value={salaryFromValue} name="salary_from" onChange={handleSalaryInputChange} />
										do <input value={salaryToValue} name="salary_to" onChange={handleSalaryInputChange} />
									</div>
									<div>
										<MultiRangeSlider
											min={minSalary}
											max={maxSalary}
											step={salaryStep}
											stepOnly={true}
											minValue={filters.salary_from}
											maxValue={filters.salary_to}
											label={false}
											ruler={false}
											style={{ border: 'none', boxShadow: 'none', padding: '0' }}
											barLeftColor="white"
											barRightColor="#FFFFFF"
											barInnerColor="#D9D9D9"
											onInput={(e) => handleSalaryRangeChange(e)}
										/>
									</div>
								</div>}
							</div>
							<span className="button" onClick={toggleSalaryDropdown}><img src={arrow} alt="" /></span>
						</span>
					</div>
				</div>
			</div>
			<div className="block-search-buttons">
				<div className="block-search-button">
					<button className="btn btn-primary" onClick={handleSearch}>ZASTOSUJ FILTRY</button>
				</div>
				<div className="block-search-button">
					<button className="btn btn-outline-primary" onClick={clearFilters}>WYCZYŚĆ FILTRY</button>
				</div>
			</div>
		</>
	)
}

export default SearchFields
