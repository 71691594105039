import { combineReducers } from 'redux'
import authReducer from './auth.reducer'
import userReducer from './user.reducer'
import contentReducer from './content.reducer'
import organizationReducer from './organization.reducer'
import projectReducer from './project.reducer'
import companyReducer from './company.reducer'
import offerReducer from './offer.reducer'
import searchReducer from './search.reducer'
import chatReducer from './chat.reducer'

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  content: contentReducer,
  organization: organizationReducer,
  project: projectReducer,
  company: companyReducer,
  offer: offerReducer,
  search: searchReducer,
  chat: chatReducer,
})

export default rootReducer
