import {
  SET_ACCOUNT_TYPE_SUCCESS,
  SET_ACCOUNT_TYPE_FAILURE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE,
  SAVE_PROFILE_SUCCESS,
  SAVE_PROFILE_FAILURE,
  CONFIRM_SKILL_SUCCESS,
  CONFIRM_SKILL_FAILURE,
  GET_VIEW_USER_REQUEST,
  GET_VIEW_USER_SUCCESS,
  GET_VIEW_USER_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
} from '../actions/user'

const initialState = {
  viewUserLoading: true,
  viewUser: null,
  users: [],
  error: null,
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        error: null
      } 
    case SET_ACCOUNT_TYPE_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        error: null
      } 
    case UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case SAVE_PROFILE_SUCCESS:
      return {
        ...state,
        error: null
      } 
    case SAVE_PROFILE_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case CONFIRM_SKILL_SUCCESS:
      return {
        ...state,
        error: null
      } 
    case CONFIRM_SKILL_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case GET_VIEW_USER_REQUEST:
      return {
        ...state,
        viewUserLoading: true
      }
    case GET_VIEW_USER_SUCCESS:
      return {
        ...state,
        viewUserLoading: false,
        viewUser: action.payload,
        error: null
      }
    case GET_VIEW_USER_FAILURE:
      return {
        ...state,
        viewUserLoading: false,
        viewUser: null,
        error: action.payload
      }
    case GET_USERS_REQUEST:
      return {
        ...state
      }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        error: null
      }
    case GET_USERS_FAILURE:
      return {
        ...state,
        users: [],
        error: action.payload
      }
    default:
      return state
  }
}

export default userReducer
